import { AwsRum, AwsRumConfig } from "aws-rum-web";
import getConfig from "next/config";

let awsRum: AwsRum | undefined = undefined;
try {
    const { publicRuntimeConfig } = getConfig();

    const config: AwsRumConfig = {
        // Application-specific Configurations https://github.com/aws-observability/aws-rum-web/blob/main/docs/configuration.md
        sessionSampleRate: 1,
        guestRoleArn: publicRuntimeConfig.NEXT_PUBLIC_AWS_RUM_GUEST_ROLE,
        identityPoolId: publicRuntimeConfig.NEXT_PUBLIC_AWS_RUM_POOL_ID,
        endpoint: `https://dataplane.rum.${publicRuntimeConfig.NEXT_PUBLIC_AWS_RUM_APP_REGION}.amazonaws.com`,
        telemetries: [
            "errors",
            [
                "http",
                {
                    urlsToExclude: [
                        // Exclude polling api requests
                        new RegExp("/clinician-api/consult/get_assigned_consults"),
                        new RegExp("/clinician-api/consult/get_available_consults"),
                        new RegExp("/clinician-api/episode/dosespot_refills_errors_counts"),
                        new RegExp("/clinician-api/episode/dosespot_refills_errors_url"),
                        new RegExp("/clinician-api/message"),
                        new RegExp("/status"), // TODO: Regex for matching http://localhost:3000/clinician-api/consult/9d50c5e5-c31f-4d8c-b304-c72b5d4527eb/status
                    ],
                },
            ],
        ],
        allowCookies: false,
        enableXRay: true,
    };

    const APPLICATION_ID: string = publicRuntimeConfig.NEXT_PUBLIC_AWS_RUM_APP_ID!;
    const APPLICATION_VERSION: string = "1.0.0";
    const APPLICATION_REGION: string = publicRuntimeConfig.NEXT_PUBLIC_AWS_RUM_APP_REGION;

    awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
    awsRum.addSessionAttributes({
        "smd:service": "clinician-portal",
        "smd:envSlug": publicRuntimeConfig.NEXT_PUBLIC_ENV_SLUG,
    });
} catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
}

export function recordError(err: any) {
    awsRum?.recordError(err);
}

export function recordEvent(eventType: string, eventData?: Object) {
    awsRum?.recordEvent(eventType, eventData || {});
}

export function addSessionAttributes(sessionAttributes: { [key: string]: string | boolean | number }) {
    awsRum?.addSessionAttributes(sessionAttributes);
}

export enum AWSRumEventTypeEnum {
    ClinicianLastSeenAt = "clinician-last-seen-at",
    ClinicianSignOut = "clinician-sign-out",
    ConsultDashboardEntered = "consult-dashboard-entered",
    ConsultDashboardFiltered = "consult-dashboard-filtered",
    ConsultDetailEntered = "consult-detail-entered",
    ConsultStatusComplete = "consult-status-complete",
    ConsultStatusInCall = "consult-status-in-call",
    NextConsultModalStart = "next-consult-modal-start",
    NextConsultModalQueue = "next-consult-modal-queue",
    VideoVisitCameraAutomaticallyEnabled = "video-visit-debug-camera-automatically-enabled",
    VideoVisitCameraManuallyEnabled = "video-visit-debug-camera-manually-enabled",
    VideoVisitCameraAutomaticallyDisabled = "video-visit-debug-camera-automatically-disabled",
    VideoVisitCameraManuallyDisabled = "video-visit-debug-camera-manually-disabled",
    VideoVisitStarted = "video-visit-started",
    VideoVisitTidyUpCalled = "video-visit-debug-tidy-up-called",
    VideoVisitEnded = "video-visit-ended",
}
