import { InputTextarea } from "primereact/inputtextarea";
import React, { useState } from "react";
import { renderDialog } from "../consult/ConsultModals";

export interface QueueModalProps {
    isVisible: boolean;
    cancelFn: (props?: any) => void;
    completeFn: (props?: any) => void;
}

export function AcceptSyncVideoConsultModal({ isVisible, cancelFn, completeFn }: QueueModalProps) {
    const style = { maxWidth: "516px" };
    const header = "Are you camera-ready?";
    const cancel = "No, take me back";
    const complete = "Yes, I'm ready";
    const children = (
        <span>
            <p className="text-sm font-normal" style={{ marginTop: "0", marginBottom: "24px", lineHeight: "24px" }}>
                This is a sync consult where you will need to be <strong>on camera</strong>.<br />
                The patient will be notified that the consult is starting.
            </p>
            <img src="/are-you-camera-ready.svg" alt="Are You Camera Ready?" style={{ width: "100%" }} />
        </span>
    );
    return renderDialog({
        isVisible,
        style,
        header,
        cancel,
        cancelFn,
        complete,
        completeFn,
        children,
        resizable: false,
    });
}

export function AcceptSyncPhoneConsultModal({ isVisible, cancelFn, completeFn }: QueueModalProps) {
    const style = { maxWidth: "516px" };
    const header = "Are you ready to provide care?";
    const cancel = "No, take me back";
    const complete = "Yes, I'm ready";
    const children = (
        <span>
            <p className="text-sm font-normal" style={{ marginTop: "0", marginBottom: "24px", lineHeight: "24px" }}>
                Accepting this sync consult will notify the patient that their consult is starting.
            </p>
            <img src="/consult-is-starting.svg" alt="Consult Is Starting" style={{ width: "100%" }} />
        </span>
    );
    return renderDialog({
        isVisible,
        style,
        header,
        cancel,
        cancelFn,
        complete,
        completeFn,
        children,
        resizable: false,
    });
}

export function AsyncOnlyReasonModal({ isVisible, cancelFn, completeFn }: QueueModalProps) {
    const style = { maxWidth: "516px" };
    const header = "Please provide a reason";
    const cancel = "Cancel";
    const complete = "Filter by Async Consults Only";
    const [reason, setReason] = useState("");
    const children = (
        <span>
            <p className="text-sm font-normal" style={{ marginTop: "0", marginBottom: "24px", lineHeight: "24px" }}>
                Please provide MedOps with a reason for why you are filtering by Async consults only.<span style={{ color: "red" }}>*</span>
            </p>
            <InputTextarea
                style={{ width: "100%" }}
                placeholder={"Enter a reason"}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                autoResize={true}
            />
        </span>
    );
    return renderDialog({
        isVisible,
        style,
        header,
        cancel,
        cancelFn,
        complete,
        completeFn: () => completeFn(reason),
        children,
        resizable: false,
        isCompleteDisabled: !reason.trim(),
    });
}
